import { useEffect } from "react";

const useEditLanguage = (i18n, setCurrentLang) => {
  useEffect(() => {
    console.log(i18n.language)
    if (i18n.language === "english") {
      setCurrentLang("en");
    } else if (i18n.language === "hebrew") {
      setCurrentLang("he");
    } else if (i18n.language === "arabic") {
      setCurrentLang("en");
    } else if (i18n.language === "zh-CN") {
      setCurrentLang("en");
    } else if (i18n.language === "yiddish") {
      setCurrentLang("en");
    } else if (i18n.language === "zh-TW") {
      setCurrentLang("en");
    } else if (i18n.language === "spanish") {
      setCurrentLang("en");
    } else if (i18n.language === "azerbaijani") {
      setCurrentLang("en");
    }
  }, [i18n.language]);
  return <></>;
};

export default useEditLanguage;
