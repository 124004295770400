export const imagesKeywords = [
    "image",
    "create an image",
    "generate an image"
];

export const checkIfImage = (inputLowerCase) => {
    const isImagesKeywords = imagesKeywords.some((keyword) =>
        inputLowerCase.includes(keyword)
    );
    return isImagesKeywords;
}

