export const TEAM_ID = "T01";

export const responses = [
    "You can explore these links for a deeper understanding of this concept.",
    "Take a look at these sources for a deeper understanding.",
    "You can find more information in the sources shared above.",
    "Dive deeper into this subject by exploring these resources.",
    "In case you're interested in learning more, these sources can provide additional insights.",
    "Here are some links in case you want to go deeper."
]

export const countries = [
    { name: "English", code: "english", flag: "https://digitalrosh.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png" },
    { name: "Arabic", code: "arabic", flag: "https://flagcdn.com/16x12/sa.png" },
    { name: "Hebrew", code: "hebrew", flag: "https://digitalrosh.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/he.png" },
    { name: "Yiddish", code: "yiddish", flag: "/assets/images/yd.png" },
    { name: "Spanish", code: "spanish", flag: "/assets/images/sp.png" },
    { name: "Azerbaijani", code: "azerbaijani", flag: "/assets/images/az.svg" },
    { name: "Chinese (Simplified)", code: "zh-CN", flag: "/assets/images/ch.png" },
    { name: "Chinese (Traditional)", code: "zh-TW", flag: "/assets/images/ch.png" },
  
  ];

export const categories = [
    { id: 2, label: "Quantum Computing", category: "technology" },
    { id: 3, label: "AI", category: "technology" },
    { id: 4, label: "Bio", category: "technology" },
    { id: 5, label: "Cloud", category: "technology" },
    { id: 6, label: "Cyber", category: "technology" },
    { id: 7, label: "Data", category: "technology" },
    { id: 8, label: "Drones", category: "technology" },
    { id: 9, label: "IOT", category: "technology" },
    { id: 10, label: "Metaverse", category: "technology" },
    { id: 11, label: "NFT", category: "technology" },
    { id: 12, label: "Robotics", category: "technology" },
    { id: 13, label: "3D Printing", category: "technology" }
]
export const authors = [
    { "id": 14, "label": "Assaf Kadosh", "category": "author" },
    { "id": 15, "label": "Avraham Kadar", "category": "author" },
    { "id": 16, "label": "Ayelet Nir Shtaier", "category": "author" },
    { "id": 17, "label": "Dr. Dana Bar-On", "category": "author" },
    { "id": 18, "label": "Dr. Vladi Dvoyris", "category": "author" },
    { "id": 19, "label": "Geri Mendelsohn", "category": "author" },
    { "id": 20, "label": "Gila Tolub", "category": "author" },
    { "id": 21, "label": "Golan Alster", "category": "author" },
    { "id": 22, "label": "Ichak Adizes", "category": "author" },
    { "id": 23, "label": "Ido Yavnai", "category": "author" },
    { "id": 24, "label": "Jacob Eilon", "category": "author" },
    { "id": 25, "label": "Nir Ekron", "category": "author" },
    { "id": 26, "label": "Noga Agai", "category": "author" },
    { "id": 27, "label": "Roee Sivan", "category": "author" },
    { "id": 28, "label": "Sharon Yehuda", "category": "author" },
    { "id": 29, "label": "Tal Kirshner", "category": "author" },
    { "id": 30, "label": "Tamar Schejter-Cohen", "category": "author" },
    { "id": 31, "label": "Tanya Zhelezniak", "category": "author" },
    { "id": 32, "label": "Tomer Simon", "category": "author" },
    { "id": 33, "label": "Tzafnat Shpak", "category": "author" },
    { "id": 34, "label": "Udi Vishengrad", "category": "author" },
    { "id": 35, "label": "Yesha Sivan", "category": "author" },
    { "id": 36, "label": "Yonatan Rabinovitch", "category": "author" },
    { "id": 37, "label": "Yossi Aviv", "category": "author" },
    { "id": 38, "label": "Yossi Chamo", "category": "author" }
]

export const industries = [
    { id: 39, label: "FemTech", category: "industry" },
    { id: 40, label: "Agro", category: "industry" },
    { id: 41, label: "Big Tech", category: "industry" },
    { id: 42, label: "Cities", category: "industry" },
    { id: 43, label: "Defense", category: "industry" },
    { id: 44, label: "Digital Art", category: "industry" },
    { id: 45, label: "FinTech", category: "industry" },
    { id: 46, label: "Government", category: "industry" },
    { id: 47, label: "Insurance", category: "industry" },
    { id: 48, label: "Manufacturing", category: "industry" },
    { id: 49, label: "Medical", category: "industry" },
    { id: 50, label: "Social", category: "industry" },
    { id: 51, label: "Transportation", category: "industry" }
]
export const crossindustries = [
    { id: 52, label: "Ethics", category: "crossindustry" },
    { id: 53, label: "Mindful Leadership", category: "crossindustry" },
    { id: 54, label: "Future of Work", category: "crossindustry" },
    { id: 55, label: "Innovation", category: "crossindustry" },
    { id: 56, label: "Learning", category: "crossindustry" },
    { id: 57, label: "Red Path (Digital Fails)", category: "crossindustry" },
    { id: 58, label: "Sustainability", category: "crossindustry" }
]
export const functions = [
    { id: 59, label: "Strategy", category: "functions" },
    { id: 60, label: "Marketing", category: "functions" },
    { id: 61, label: "Consultancy", category: "functions" },
    { id: 62, label: "CIOs", category: "functions" }
]

export const options1 = [
    { id:2, label:"Voice Call", category:"products"},
    { id:3, label:"Video Call",category:"products"},
    { id:4, label:"Call Recording", category:"products"},
    { id:5, label:"Messaging", category:"products"},
    { id:6, label:"Enhance", category:"products"},
    { id:7, label:"Analyze", category:"products"}
]
export const options2 = [
    { id: 8, label: 'Communications APIs', category: "Developer" },
    { id: 9, label: 'Steaming APIs', category: "Developer" },
    { id: 10, label: 'Media APIs', category: "Developer" },
];

export const options3 = [
    { id: 11, label: 'Media', category: "Industry Solutions" },
    { id: 12, label: 'Product', category: "Industry Solutions" },
    { id: 13, label: 'Research', category: "Industry Solutions" },
    { id: 14, label: 'Streaming', category: "Industry Solutions" },
];


export const insights = [
  "Lots of developers are looking for help integrating the APIs with ReactJS",
  "The champions program is getting a lot of interest after the AWE last event",
  "The blog was the most loved resource with people averaging close to 12 mins on it per post and coming back multiple times",
  "There was a lack of content around customer onboarding and getting started for early stage companies",
  "Real time streaming services was another topic people looked for but could not find much information on"
]

export const options4 = {
    chart: {
      type: 'spline',
      height:300
    },
    title: {
      text: ''
    },
    credits:{
        enabled:false
    },
    series: [
      {
        data: [1, 2, 1, 4, 3, 6,10, 11, 12,13,15,15, 4]
      }
    ]
  };
export const options5 = {
    chart: {
      type:"bar",
      height: 300,
    },
    title: {
      text: "",
    },
    yAxis: {
      title: {
        text: 'Values',
      },
    },
    series: [
      {
        data: [1, 2, 1, 4, 3, 6],
      },
      {
        data: [2, 7, 0, 4, 6, 2],
      },
    ],
  }

  export const options6 = {
    chart: {
      type: 'pie',
      height:300
    },
    title: {
      text: ''
    },
    credits:{
        enabled:false
    },
    series: [
      {
        data: [1, 2, 1, 4, 3, 6,10, 11, 12,13,15,15, 4]
      }
    ]
  };