import { useEffect, useRef, useState } from "react";
import HighchartComponent from "../charts/HighchartComponent";
import MermaidComponent from "../charts/MermaidComponent";
import SourcesComponent from "../charts/SourcesComponent";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const ChatL = ({
  isLoading,
  errorSyntax,
  setErrorSyntax,
  chatLog,
  globalLang,
  selectedOptions
}) => {
  // we create a reference to get the height of the chatLog, it updates the view when there is a scroll to go down directly
  const chatLogContainerRef = useRef(null);
  const [copiedMessageIndex, setCopiedMessageIndex] = useState(null);

  useEffect(() => {
    chatLogContainerRef.current.scrollTop =
      chatLogContainerRef.current.scrollHeight;
  }, [chatLog, isLoading]);

  const calculateChatContainerHeight = () => {
    const screenWidth = window.innerWidth;
    let baseHeight;

    if (screenWidth <= 999) {
      baseHeight = 'calc(100vh - 380px)';
    } else if (screenWidth <= 1024) {
      baseHeight = 'calc(100vh - 410px)';
    } else if (screenWidth <= 1410) {
      baseHeight = 'calc(100vh - 310px)';
    } else {
      baseHeight = 'calc(100vh - 350px)';
    }

    const expandedHeight = `calc(${baseHeight} - 90px)`;

    return selectedOptions.length > 0 ? expandedHeight : baseHeight;
  };

 
  const handleClickWithMessage = (data, index) => {
    // Set the copied message index
    setCopiedMessageIndex(index);

    // Call the original handleClick function
    handleClick(data);

    // Reset the copied message index after 2 seconds
    setTimeout(() => setCopiedMessageIndex(null), 2000);
  }

  const handleClick = (data)=> {
    const urls = data.preview.map((source) => source.url);
    const copyText = data.content+ "\n\n" + urls.join('\n');
    navigator.clipboard.writeText(copyText);
  }

  return (
    <div style={{ height: calculateChatContainerHeight() }}
      className=" overflow-y-auto flex-grow"
      ref={chatLogContainerRef}
    >
      {chatLog.map((data, index) => {
        return (
          <div key={index}>
            {data.role === "user" ? (
              <div className="flex items-end mt-4" style={globalLang ? { direction: "rtl" } : {}}>
                <span>
                  <img
                    className="w-[46px] h-[46px] rounded-[4px]"
                    src="/assets/images/user.png"
                    alt="user"
                  />
                </span>
                <div className="rounded-tl-lg rounded-tr-lg rounded-br-lg max-w-xl bg-gray-400 ml-[12px]">
                  <p className="text-[16px] text-gray-900 p-4">
                    {data.content}
                  </p>
                </div>
              </div>
            ) : (
              <div>
                {/* It display the response we get, it is gonna be displayed only if there was no error, no highchart or mermaid */}
                {data.content &&
                  !data.errorEl &&
                  !data.highchartCode &&
                  !data.mermaidCode && (
                    <div className="relative flex items-end justify-end mt-4" >
                      <div style={globalLang ? { direction: "rtl" } : {}} className=" rounded-tl-lg rounded-tr-lg rounded-bl-lg max-w-xl bg-blue-500">
                        <ReactMarkdown  children={data.content} remarkPlugins={[remarkGfm]}
                         className="whitespace-pre-wrap font-arimo text-[16px] font-normal text-white p-4" />
                      </div>
                      {copiedMessageIndex === index && <div className="absolute top-[-40px] right-[120px]">
                        <span className="text-sm text-green-500">Copied!</span>
                      </div>}
                      <div className="flex copyItem absolute bg-[#08345C] top-[-13px] right-[83px] rounded-[6px] px-[8px] py-[4px] border-2 border-blue-800">
                        <img 
                        onClick={()=>handleClickWithMessage(data, index)}
                        src="/assets/icons/copy.svg" 
                        alt="copy" 
                        className="cursor-pointer"/>
                      <img src="/assets/icons/flag.svg" alt="flag" className="cursor-pointer mx-[6px]"/>
                      <img src="/assets/icons/share.svg" alt="share" className="cursor-pointer"/>
                      </div>
                     
                      <img
                        className="w-[46px] h-[46px] ml-[12px] mr-[8px]"
                        src={`/assets/images/digitalAi.png`}
                        alt="worker"
                      />
                    </div>
                  )}
                {/* Error related to the server or backend */}
                {data.errorEl && (
                  <div className="flex items-end justify-end mt-4">
                    <div
                      style={{
                        color: "red",
                        fontSize: "16px",
                        marginLeft: "2rem",
                      }}
                    >
                      There seems to be an error - we are looking in and will
                      fix it ASAP
                    </div>
                    <img
                      className="w-[46px] h-[46px]  ml-[12px] mr-[8px]"
                      src={`/assets/images/digitalAi.png`}
                      alt="worker"
                    />
                  </div>
                )}
                {/* Error related to highchart if it has a syntax error */}
                {errorSyntax[data.highchartId] && (
                  <div className="flex items-end justify-end mt-4">
                    <div
                      style={{
                        color: "red",
                        fontSize: "16px",
                        marginLeft: "2rem",
                      }}
                    >
                      There seems to be an error - Please retry again
                    </div>
                    <img
                      className="w-[46px] h-[46px]  ml-[12px] mr-[8px]"
                      src={`/assets/images/digitalAi.png`}
                      alt="worker"
                    />
                  </div>
                )}

                {/* Mermaid component that is going to be diplayed only if the user asks about a diagram */}
                <MermaidComponent
                  mermaidCode={data.mermaidCode}
                  mermaidError={data.mermaidError}
                  mermaidId={data.mermaidId}
                  chatLog={chatLog}
                />
                {/* Highchart component that is going to be diplayed only if the user asks about a highchart without any syntax error */}
                {data.highchartCode && !errorSyntax[data.highchartId] && (
                  <HighchartComponent
                    index={index}
                    data={data.highchartId}
                    setErrorSyntax={setErrorSyntax}
                    chatLog={chatLog}
                  />
                )}

                {/* This displays the image we get from midjourney */}
                {data.imgUrl && (
                  <div className="flex items-end justify-end mt-4">
                    <div className="rounded-tl-lg rounded-tr-lg rounded-bl-lg max-w-xl bg-blue-500">
                      <img
                        src={data.imgUrl}
                        alt="generated art"
                        className="w-full"
                      />
                    </div>
                    <img
                      className="w-[46px] h-[46px]  ml-[12px] mr-[8px]"
                      src={`/assets/images/digitalAi.png`}
                      alt="midjourney generated pic"
                    />
                  </div>
                )}

                <SourcesComponent
                  data={data}
                  globalLang={globalLang}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default ChatL;
