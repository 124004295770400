import React from 'react'

const ChatHeader = ({ t, isLoading, globalLang }) => {
    return (
        <div className="flex mb-6 items-center">
            <img className="w-[46px] h-[46px] mr-4 rounded-[4px]"
                src={`/assets/images/digitalAi.png`}
                alt="user pic" />
            <div className="w-full flex justify-between">
                <div className="flex flex-col" >
                    <h4 style={globalLang ? { direction: "rtl" } : {}}
                        className="text-[16px] font-bold text-gray-900 leading-[18px] mb-[2px]">{t("chatbox.h1")}</h4>
                    <p className="text-[14px] font-light text-gray-800 leading-[20px]]">
                        <span>{isLoading ? `${t("chatbox.p2")}` : `${t("chatbox.p1")}`}</span>
                    </p>
                </div>
               <div></div>
            </div>
        </div>
    )
}

export default ChatHeader