import React from 'react'

const RightElements = ({ handleClick, selected, text }) => {

  const isSelected = (input) => {
    return selected === input ? 'border-blue-500 text-gray-900' : 'border-white text-gray-800';
  };


  return (
    <>
      <div onClick={(e) => handleClick(text, e)}
        className={` ${isSelected(text)} border-2 mt-2 flex items-center cursor-pointer py-1 2xl:p-4 px-[16px] rounded-[10px] bg-white`}>
        <span className="mr-2">
          <svg height="20px" width="20px" version="1.1" id="Capa_1"
            viewBox="0 0 31.955 31.955">
            <g>
              <circle style={{fill:"#0088CC"}} cx="15.979" cy="15.977" r="6.117" />
            </g>
          </svg>
        </span>
        <h3 className="lg:text-[16px] font-normal text-xs ml-2">
          {text}</h3>
      </div>
    </>
  )
}

export default RightElements