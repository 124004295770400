import { useState } from "react";

import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg className={`${id === open ? "rotate-180" : ""
            } transition-transform ml-0 mr-4`} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.9998 9.84685C19.0037 9.68177 18.9523 9.51966 18.8526 9.38306C18.753 9.24646 18.61 9.14215 18.4437 9.08464C18.2774 9.02713 18.096 9.01928 17.9247 9.06217C17.7534 9.10506 17.6008 9.19657 17.4879 9.32397L13.0138 14.185L8.54126 9.32397C8.47167 9.23584 8.38332 9.16204 8.28167 9.10718C8.18001 9.05231 8.06725 9.01758 7.95051 9.00515C7.83376 8.99272 7.71554 9.00285 7.6032 9.03493C7.49086 9.067 7.38681 9.12032 7.29764 9.19154C7.20846 9.26276 7.13612 9.35036 7.085 9.44884C7.03388 9.54732 7.00517 9.65456 7.00064 9.76385C6.99611 9.87314 7.0158 9.98213 7.05862 10.084C7.10144 10.1858 7.16638 10.2784 7.2494 10.3558L12.3654 15.9212C12.4455 16.0086 12.545 16.0788 12.657 16.127C12.7691 16.1751 12.8911 16.2 13.0146 16.2C13.138 16.2 13.26 16.1751 13.3721 16.127C13.4842 16.0788 13.5838 16.0086 13.6639 15.9212L18.7848 10.3558C18.919 10.2152 18.995 10.0348 18.9998 9.84685Z" fill="#716F6F" />
            <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" stroke="#E2E2E2" />
        </svg>

    );
}



const DataAccordion = () => {

    const [open, setOpen] = useState(3);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <div className="bg-white p-[20px] rounded-[6px] border border-gray-500 ">
                <div className="flex justify-between  mb-4">
                    <h3 className='text-[20px] text-black font-bold leading-[30px]'>Top queries</h3>
                </div>
                <div className="flex items-center bg-purple-500 justify-between w-full text-white px-4 py-[10px]" 
               >
                    <div className="">
                        <div className="flex items-center space-x-2" >
                            <p className="text-[16px] uppercase font-bold leading-[24px]">Queries</p>
                        </div>
                    </div>
                    <div className="flex items-center text-[16px] font-semibold">
                    <p className="uppercase leading-[24px] mr-[20px]">Time saved</p>
                    <p className="uppercase leading-[24px] mr-12">Star Rating</p>
                    <span></span>
                    </div>
                </div>
              
                <Accordion open={open === 3} className={`${open === 3 ? "bg-[#F9F8F8]" : "bg-white" } `} icon={<Icon id={3} open={open} />}>
                    <AccordionHeader onClick={() => handleOpen(3)} className={`${open === 3 ? 'text-[#14141A]' : 'text-[#6A6A6D]'} hover:underline px-4 text-[16px] font-medium `}>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex items-center space-x-2">
                                <p>What are the network requirements for Dolby.io Real-time Streaming services</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <p className="w-[120px] text-[16px] text-gray-400 font-light mr-6">15min</p>
                            <img className="w-[100px] h-[16px]" src="/assets/icons/stars.png" alt="stars" />
                        </div>
                    </AccordionHeader>
                    <AccordionBody>
                        <div className="bg-white border border-gray-500 p-4 m-4 rounded-[6px]">
                            <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
                                <div className="md:col-span-2">
                                    <h3 className='text-[16px] text-[#2C2C31]  font-semibold leading-[24px] mb-[10px]'>Response</h3>
                                    <p className="text-[#6A6A6D] text-[17px] mb-4">The network requirements for Dolby.io Real-time Streaming services are as follows:</p>
                                    <p className="text-[#6A6A6D] text-[17px] mb-4">WebRTC Flow:</p>
                                    <div style={{fontFamily:"IBM Plex Mono"}}
                                     className="text-[15px] bg-[#F8F8F8] text-[#2C2C31] border rounded-[6px] p-4">
                                        <p className="mb-4">Port 443/TCP: API calls </p>
                                        <p className="t">Ports 49152-65535/UDP: Millicast WebRTC Media Server</p>
                                        <p className=" mb-4">STUN/TURN:</p>
                                        <p className="">Ports 443, 3478, 5349/TCP and UDP: Used by Dolby.io Real-time Streaming for Twilio services</p>
                                        <p className="mb-4">RTMP publishing.</p>
                                        <p className="">Port 1935/TCP: For unsecured RTMP publishing.</p>
                                        <p className="">Port 443/TCP: For secure RTMP publishing.</p>
                                        <p className=" mb-4">SRT Publishing:</p>
                                        <p className="">Port 10000/UDP: For SRT publishing.</p>
                                    </div>

                                    <p className="text-[#6A6A6D] text-[17px] my-[15px]">Additionally, it is recommended to whitelist the following domains:</p>

                                    <div style={{fontFamily:"IBM Plex Mono"}}
                                     className="text-[15px] bg-[#F8F8F8] text-[#2C2C31] rounded-[6px] p-4">
                                        <p className="mb-4 ">*.millicast.com</p>
                                        <p className="">*.twilio.com</p>
                                    </div>
                                    <p className="text-[#6A6A6D]font-avenir-regular text-[17px] my-[15px]">By allowing network traffic through these ports and whitelisting the specified domains, you can ensure that Dolby.io Real-time Streaming services can function properly within your network environment.</p>
                                    <div className="flex flex-wrap">
                                        <button className="flex bg-transparent border border-purple-500 py-[11px] px-[14px] rounded-full uppercase text-[14px] leading-[18px] text-purple-500 font-semibold">
                                            <span className="mr-[10px]">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_992_1666)">
                                                        <path d="M9 3.63525C5.56091 3.63525 2.44216 5.51681 0.140841 8.57297C-0.0469469 8.82335 -0.0469469 9.17315 0.140841 9.42353C2.44216 12.4834 5.56091 14.3649 9 14.3649C12.4391 14.3649 15.5578 12.4834 17.8592 9.42721C18.0469 9.17683 18.0469 8.82703 17.8592 8.57665C15.5578 5.51681 12.4391 3.63525 9 3.63525ZM9.2467 12.7779C6.96379 12.9215 5.07855 11.04 5.22215 8.75339C5.33998 6.86815 6.86806 5.34007 8.7533 5.22224C11.0362 5.07864 12.9214 6.9602 12.7778 9.24679C12.6563 11.1283 11.1283 12.6564 9.2467 12.7779ZM9.13256 11.0326C7.90273 11.1099 6.88647 10.0974 6.96747 8.86753C7.03007 7.85127 7.85486 7.03016 8.87113 6.96388C10.101 6.88656 11.1172 7.89914 11.0362 9.12896C10.9699 10.1489 10.1451 10.97 9.13256 11.0326Z" fill="#051B4F" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_992_1666">
                                                            <rect width="18" height="18" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span>View transcript</span>
                                        </button>
                                        <button className="md:mx-[10px] md:my-0 my-4 flex bg-transparent border border-purple-500 py-[11px] px-[14px] rounded-full uppercase text-[14px] leading-[18px] text-purple-500 font-semibold">
                                            <span className="mr-[10px]">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18 16.875C18 17.4963 17.4963 18 16.875 18H1.125C0.503684 18 0 17.4963 0 16.875C0 16.2537 0.503684 15.75 1.125 15.75H16.875C17.4963 15.75 18 16.2537 18 16.875ZM8.20452 13.3111C8.42421 13.5308 8.71207 13.6407 9 13.6407C9.28786 13.6407 9.57586 13.5308 9.79548 13.3111L13.7814 9.32523C14.2208 8.88588 14.2208 8.17358 13.7814 7.73423C13.3421 7.29489 12.6298 7.29489 12.1904 7.73423L10.125 9.79966V1.125C10.125 0.503684 9.62132 0 9 0C8.37868 0 7.875 0.503684 7.875 1.125V9.79966L5.80957 7.73423C5.37022 7.29489 4.65792 7.29489 4.21857 7.73423C3.77923 8.17358 3.77923 8.88588 4.21857 9.32523L8.20452 13.3111Z" fill="#051B4F" />
                                                </svg>
                                            </span>
                                            <span>Download transcript</span>
                                        </button>
                                        <button className="flex bg-transparent border border-purple-500 py-[11px] px-[14px] rounded-full uppercase text-[14px] leading-[18px] text-purple-500 font-semibold">
                                            <span className="mr-[10px]">
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_992_1675)">
                                                        <g clipPath="url(#clip1_992_1675)">
                                                            <path d="M11.116 3.02344L1.21212 12.928C1.16229 12.978 1.12631 13.0411 1.10918 13.1089L0.0114342 17.515C-0.0214026 17.6476 0.0175732 17.7887 0.11437 17.8855C0.187611 17.9587 0.287406 17.9993 0.389485 17.9993C0.420752 17.9993 0.452732 17.9954 0.483855 17.9876L4.88998 16.8897C4.95865 16.8726 5.02104 16.8367 5.07087 16.7869L14.9756 6.88304L11.116 3.02344Z" fill="#051B4F" />
                                                            <path d="M17.4291 1.67288L16.3267 0.57042C15.5899 -0.166408 14.3057 -0.165695 13.5697 0.57042L12.2192 1.92087L16.0787 5.78033L17.4291 4.42988C17.7972 4.06197 17.9999 3.57227 17.9999 3.05145C17.9999 2.53063 17.7972 2.04094 17.4291 1.67288Z" fill="black" />
                                                        </g>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_992_1675">
                                                            <rect width="18" height="18" fill="white" transform="translate(18) rotate(90)" />
                                                        </clipPath>
                                                        <clipPath id="clip1_992_1675">
                                                            <rect width="18" height="18" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </span>
                                            <span>Edit response</span>
                                        </button>

                                    </div>
                                </div>
                                <div>
                                    <h3 className='text-[14px] text-black uppercase font-semibold leading-[24px] mb-[10px]'>sources</h3>
                                    <a href="#" className="hover:underline text-[16px] text-purple-500 leading-[24px] mb-[10px]">https://docs.dolby.io/communications-apis/docs/real-time-communications-network-requirements</a>
                                </div>
                            </div>
                        </div>
                    </AccordionBody>
                </Accordion>
             
            </div>
        </>
    )
}

export default DataAccordion