import { useTranslation } from "react-i18next";
import { countries } from "../../config/constants";
import useLanguage from "../../hooks/useLanguage";
import { useState } from "react";

const ArrowDown = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 4.99623C15.0007 5.12789 14.9754 5.25841 14.9254 5.3803C14.8755 5.50219 14.8018 5.61305 14.7087 5.70653L8.68312 11.7091C8.49496 11.8954 8.24043 12 7.97511 12C7.7098 12 7.45527 11.8954 7.2671 11.7091L1.24148 5.70653C1.07696 5.51515 0.990986 5.26897 1.00075 5.01719C1.01051 4.76541 1.11529 4.52656 1.29414 4.34839C1.473 4.17022 1.71276 4.06585 1.96551 4.05612C2.21826 4.0464 2.46539 4.13204 2.6575 4.29593L7.97009 9.58818L13.2827 4.29593C13.4225 4.15545 13.6011 4.05944 13.7957 4.02004C13.9904 3.98064 14.1924 3.99961 14.3762 4.07457C14.56 4.14953 14.7174 4.2771 14.8284 4.44115C14.9394 4.60521 14.9991 4.79837 15 4.99623Z" />
    </svg>
  );
};

const LeftNavbar = ({ newConversation }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [isClicked, setIsClicked] = useState(false);

  function getFlag(countries, selectedCountry) {
    const country = countries.find(function (country) {
      return (
        country.name === selectedCountry || country.code === selectedCountry
      );
    });
    return country ? country.flag : undefined;
  }

  const { selectedCountry, setSelectedCountry } = useLanguage(
    i18n,
    getFlag,
    countries
  );

  const handleClick = (code, name) => {
    setLanguage(code);
    i18n.changeLanguage(code);
    setSelectedCountry({ name: name, flag: getFlag(countries, name) });
    setIsClicked(false);
  };

  return (
    <header className="bg-white rounded-[6px] h-[82px] mb-[20px] mr-2">
      <nav className="dashNav mx-auto md:flex hidden justify-center md:justify-between items-center h-full 2xl:py-4 py-3 px-3">
          <ul className={`md:flex justify-between items-center hidden w-full`}>
            <li className="px-2 cursor-pointer rounded-[6px] hover:bg-[#0088CC]">
              <div
                className="flag-dropdown"
                onMouseEnter={() => setIsClicked(true)}
                onMouseLeave={() => setIsClicked(false)}
              >
                <div className="selected-item">
                  <img
                    className={`mr-3 w-[18px] h-[12px]`}
                    src={`${selectedCountry.flag}`}
                    alt={selectedCountry.name}
                  />
                  <ArrowDown />
                </div>
                {isClicked && (
                  <ul
                    className="dropdown-list md:left-0 md:right-0"
                    style={{ width: "250px" }}
                  >
                    {countries.map((country) => (
                      <li
                        key={country.name}
                        className="hover:bg-[#f5f5f5] mb-2"
                        onClick={() => handleClick(country.code, country.name)}
                      >
                        <img
                          src={`${country.flag}`}
                          alt={country.name}
                          className={`mr-2 w-[18px] h-[12px]`}
                        />
                        <span className="text-[16px] text-[#494949]">
                          {country.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </li>
            <li>
              <button
                onClick={newConversation}
                style={{ lineHeight: "26px" }}
                className="mt-2 text-center lg:mt-0 flex items-center text-[12px] border-[2px] border-black text-black rounded-full px-4 py-2"
              >
                <span className="xl:mr-2 plusSvg">
                  <svg
                    className=""
                    width="12"
                    height="12"
                    viewBox="0 0 16 16"
                    fill={`black`}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.208 16V9.792H0V6.176H6.208V0H9.824V6.176H16V9.792H9.824V16H6.208Z" />
                  </svg>
                </span>
                <span className="xl:flex hidden">{t("controls.p3")}</span>
              </button>
            </li>
          </ul>
      </nav>
    </header>
  );
};

export default LeftNavbar;
