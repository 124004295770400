import { useState, useEffect, useContext } from "react";
import LeftSection from "../components/sections/LeftSection";
import RightSection from "../components/sections/RightSection";
import ChatL from "../components/sections/ChatL";
import moment from "moment";
import ChatForm from "../components/sections/ChatForm";
import { handleRequest, parseAndSetBotResponse, parseAndSetUserMessage } from "../functions/submit";
import Modal from "../components/layouts/Modal";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import Tags from "../components/sections/Tags";
import { languageContext } from "../App";
import ChatHeader from "../components/layouts/ChatHeader";
import useEditLanguage from "../hooks/useEditLanguage";
import axios from 'axios';
import LeftNavbar from "../components/layouts/LeftNavbar";

const { CancelToken } = axios;


const ChatPage = () => {

    const [cancelToken, setCancelToken] = useState(null);
    const { t, i18n } = useTranslation();

    const [formData, setFormData] = useState({ input: "" });
    const [openModal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [toggleOne, setToggleOne] = useState(false);
    const [toggleTwo, setToggleTwo] = useState(false);
    const [toggleThree, setToggleThree] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedIndustries, setSelectedIndustries] = useState("");
    const [selectedCross, setSelectedCross] = useState("");
    const [selectedFunctions, setSelectedFunctions] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [currectLang, setCurrentLang] = useState(i18n.language === "en-US" ? "en" : i18n.language);
    const { globalLang } = useContext(languageContext);


    const [conversationId, setConversationId] = useState(0);
    const { input } = formData;
    const { user } = useAuth0();
    const [chatLog, setChatLog] = useState([]);
    const [errorSyntax, setErrorSyntax] = useState({});

    useEditLanguage(i18n, setCurrentLang);

    const newConversationId = () => {
        setConversationId(moment().format("x"))
    }

    useEffect(() => {
        newConversationId()
    }, [])

    const newConversation = () => {
        window.location.href = "/";
    };



    const handleSubmit = async (data, e) => {
        e && e.preventDefault();

        if (!user) {
            setOpenModal(true);
        } else {
            setIsLoading(true);
            const { element, reqType } = parseAndSetUserMessage(data, setChatLog, input, setFormData, formData);
            let categories = [...selectedCategory, ...selectedIndustries, ...selectedCross, ...selectedFunctions];
            if (cancelToken) {
                cancelToken.cancel();
            }
            const source = CancelToken.source();
            setCancelToken(source);
            try {
                const result = await handleRequest(data, toggleOne, toggleThree, element, reqType, { categories: categories }, conversationId, user.sub, currectLang, source);
                await parseAndSetBotResponse(result, element, setChatLog, errorSyntax, setIsLoading, source);
            } catch (error) {
                if (axios.isCancel(error)) {
                    setIsLoading(false);
                    console.log('Request canceled');
                } else {
                    setChatLog(chatLog => [...chatLog, {
                        role: "assistant",
                        errorEl: true
                    }]);
                    setIsLoading(false);
                    console.log(error);
                }

            }
        }
    };
    const calculateChatContainerHeight = () => {
        const screenWidth = window.innerWidth;
        let baseHeight;
    
        if (screenWidth <= 999) {
          baseHeight = 'calc(100vh - 210px)';
        } else if (screenWidth <= 1024) {
          baseHeight = 'calc(100vh - 240px)';
        } else if (screenWidth <= 1410) {
          baseHeight = 'calc(100vh - 140px)';
        } else {
          baseHeight = 'calc(100vh - 180px)';
        }
        
        return baseHeight;
      };
    

    return (
        <>
            <main className="mx-auto md:px-[40px] px-[12px]">
                <div className="flex flex-col lg:flex-row" style={{ flexWrap: "wrap" }}>
                    {/* It is the left side that contains filters */}
                    <div className="mt-4 flex-col lg:w-1/5 lg:flex hidden overflow-y-scroll" 
                    style={{ height: calculateChatContainerHeight() }}>
                    <LeftNavbar newConversation={newConversation}/>
                    <LeftSection
                        toggleOne={toggleOne}
                        toggleTwo={toggleTwo}
                        toggleThree={toggleThree}
                        setToggleOne={setToggleOne}
                        setToggleTwo={setToggleTwo}
                        setToggleThree={setToggleThree}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        setSelectedCategory={setSelectedCategory}
                        setSelectedCross={setSelectedCross}
                        setSelectedIndustries={setSelectedIndustries}
                        setSelectedFunctions={setSelectedFunctions}

                    />
                    <RightSection handleSubmit={handleSubmit} formData={formData} setFormData={setFormData} />
                    </div>

                    <section className="flex flex-col justify-between lg:w-4/5 w-full h-full">
                        <div className="flex flex-col h-full">
                            <Tags
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                                selectedCategory={selectedCategory}
                                selectedCross={selectedCross}
                                selectedFunctions={selectedFunctions}
                                selectedIndustries={selectedIndustries}
                                setSelectedCategory={setSelectedCategory}
                                setSelectedCross={setSelectedCross}
                                setSelectedIndustries={setSelectedIndustries}
                                setSelectedFunctions={setSelectedFunctions}
                            />
                            <div className="px-[20px] py-4 mx-[40px] mt-4 bg-white rounded-[6px]">
                                <ChatHeader
                                    t={t}
                                    isLoading={isLoading}
                                    globalLang={globalLang}
                                />
                                {/* Where we display the chat messages (what the user sent and the respose from backend) */}
                                <ChatL
                                    globalLang={globalLang}
                                    isLoading={isLoading}
                                    errorSyntax={errorSyntax}
                                    setErrorSyntax={setErrorSyntax}
                                    chatLog={chatLog}
                                    selectedOptions={selectedOptions}
                                />
                                {/* the form that has the submit function the send/receive requests/responses */}
                                <ChatForm
                                    t={t}
                                    globalLang={globalLang}
                                    handleSubmit={handleSubmit}
                                    isLoading={isLoading}
                                    formData={formData}
                                    cancelToken={cancelToken}
                                    setFormData={setFormData}
                                />
                            </div>
                        </div>
                    </section>
                    {/* the section in the right that has prompts */}
                </div >
                <Modal t={t} openModal={openModal} setOpenModal={setOpenModal} />
            </main >
        </>
    )
}
export default ChatPage