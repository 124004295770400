import { useContext, useState } from "react";
import RightElements from "../layouts/RightElements";
import { useTranslation } from "react-i18next";
import { languageContext } from "../../App";


const RightSection = ({handleSubmit, setFormData, formData }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState('');
  const { globalLang} = useContext(languageContext);

  const handleClick = async (data, e) => {
    setFormData({ ...formData, input: data });
    setSelected(data);
    handleSubmit(data, e)
  }

  return (
    <section className="lg:flex hidden flex-col justify-between mr-2" style={{ height: "calc(100vh - 160px)"}}>
      <div className="" style={globalLang? {direction:"rtl"} : {}}> 
        <div className="flex items-center 2xl:mb-[20px]">
          <h3 className="text-gray-900 2xl:text-[20px] text-[16px] font-semibold flex">
            {t("right.h1")}</h3>
        </div>
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h2")} />
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h3")} />
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h4")} />
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h5")} />
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h6")} />
        <RightElements handleClick={handleClick} selected={selected} text={t("right.h7")} />
      </div>
    </section>
  )
}
export default RightSection
