import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from "react-i18next";


const LoginBtn = () => {
    const { t } = useTranslation();
    const {isAuthenticated, loginWithRedirect, logout } =
        useAuth0();
  
    return (
        <>
            { !isAuthenticated && <div className="flex flex-col md:flex-row justify-end">
                <button onClick={() => loginWithRedirect()} className="md:mr-4  md:mb-0 mb-2 btn">
                {t('header.signin')}
                </button>
                <button onClick={() => loginWithRedirect({login_hint: "signup" })} className="btn">
                {t('header.signup')}
                </button>
            </div>}
            { isAuthenticated &&  <div className="flex flex-col md:flex-row justify-end">
                <a href="https://digitalrosh.com/my-dashboard/" className="md:mr-4 md:mb-0 mb-2 btn">
                {t('header.dashboard')}
                </a>
                <button onClick={() => logout({ returnTo: window.location.origin })} className="btn">
                {t('header.logout')}
                </button>
            </div>}
        </>
    );

}

export default LoginBtn