import { useState } from "react";
import LoginBtn from "./LoginBtn";
import { useTranslation } from "react-i18next";


const Header = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);


  return (
    <>
      <header className="bg-white h-[82px] mb-[40px]">
        <nav className="dashNav mx-auto md:flex hidden justify-center md:justify-between items-center h-full 2xl:py-4 py-3 px-[40px]">
          <div className="flex items-center">
            <div className="flex">
              <img
                className="w-[120px]"
                src="/assets/images/mindli-logo.png"
                alt="Mindly logo"
              />

            </div>
            <ul className={` items-center hidden md:flex pl-8`}>
              <li className="p-2 font-bold text-[16px] rounded-[6px] hover:bg-[#0088CC]">
                <a rel="noreferrer" href="https://digitalrosh.com/about" target="_blank" className="hover:text-white mr-3">{t("header.about")}</a>
              </li>
              <li className="p-2 font-bold text-[16px] rounded-[6px] hover:bg-[#0088CC]">
                <a rel="noreferrer" href="https://digitalrosh.com/our-courses/coursedir/" target="_blank" className="hover:text-white mr-3">{t("header.courses")}</a>
              </li>
            </ul>
          </div>
          <LoginBtn />
        </nav>
        <nav className="mobileNav h-full mx-auto flex md:hidden justify-between items-center 2xl:py-4 py-3 px-12">
          <div className="flex items-center justify-center cursor-pointer">
            <img
              className="w-[150px] md:w-[190px]"
              src="/assets/images/digitalLogo.png"
              alt="DigitalRush logo"
            />
          </div>

          <div onClick={() => setOpen(!open)} className="text-3xl absolute right-8 top-5 cursor-pointer md:hidden">
            <i className={`fa-solid fa-${open ? 'x' : 'bars'} text-black`}></i>
          </div>

          <div className={`md:z-auto z-[1] left-0 w-full md:w-auto px-12 transition-all border-b border-gray-200 duration-500 ease-in md:flex md:pb-0 pb-12 absolute md:static bg-white md:items-center text-md ${open ? 'top-20' : 'top-[-490px] md:opacity-100 opacity-0'}`}>
            <div className="flex flex-col">
              <ul className="flex">
                <li className="p-2 font-bold text-[16px] rounded-[6px] hover:bg-[#0088CC]">
                  <a href="https://digitalrosh.com/about" target="_blank" className="hover:text-white mr-3">{t("header.about")}</a>
                </li>
                <li className="p-2 font-bold text-[16px] rounded-[6px] hover:bg-[#0088CC]">
                  <a href="https://digitalrosh.com/our-courses/coursedir/" target="_blank" className="hover:text-white mr-3">{t("header.courses")}</a>
                </li>
              </ul>
              <LoginBtn />
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Header;
