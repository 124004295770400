import React from "react";
import { categories, crossindustries, functions, industries } from "../../config/constants";
import TagItem from "../layouts/TagItem";

const Tags = ({
    selectedOptions,
    selectedCategory,
    setSelectedCategory,
    selectedIndustries,
    setSelectedFunctions,
    selectedFunctions,
    setSelectedIndustries,
    setSelectedOptions,
    setSelectedCross,
   selectedCross
}) => {
    const removeTag = (setSelected, dataset) => (tagToRemove) => {
        setSelected((prevTags) => 
          prevTags.filter((tag) => tag !== tagToRemove)
        );
        setSelectedOptions((prevSelectedOptions) =>
          prevSelectedOptions.filter(
            (optionId) =>
              !dataset
                .filter((option) => option.label === tagToRemove)
                .map((option) => option.id)
                .includes(optionId)
          )
        );
      };
      const removeCategory = removeTag(setSelectedCategory, categories);
      const removeFunction = removeTag(setSelectedFunctions, functions);
      const removeIndustry = removeTag(setSelectedIndustries, industries);
      const removeCross = removeTag(setSelectedCross, crossindustries);


  return (
    <>
      {selectedOptions && selectedOptions.length > 0 && 
      <div className="mx-[40px] flex flex-wrap items-center px-[20px] py-4 bg-white border border-gray-300">
        <TagItem item="Remove All" remove={() =>setSelectedOptions([])} />

        {selectedCategory &&
          selectedCategory.length > 0 &&
          selectedCategory.map((item, index) => {
            return <TagItem key={index} item={item} remove={removeCategory} />;
          })}

        {selectedFunctions &&
          selectedFunctions.length > 0 &&
          selectedFunctions.map((item, index) => {
            return <TagItem key={index} item={item} remove={removeFunction} />;
          })}

        {selectedIndustries &&
          selectedIndustries.length > 0 &&
          selectedIndustries.map((item, index) => {
            return <TagItem key={index} item={item} remove={removeIndustry} />;
          })}

        {selectedCross &&
          selectedCross.length > 0 &&
          selectedCross.map((item, index) => {
            return <TagItem key={index} item={item} remove={removeCross} />;
          })}
      </div>}
    </>
  );
};

export default Tags;
