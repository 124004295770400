export const mermaidKeywords = [
  "diagram",
  "mermaid",
  "mermaidjs",
  "sequence",
  "flowchart",
  "classdiagram",
  "classDiagram",
];

export const checkIfMermaid = (inputLowerCase) => {
  const isMermaidRequest = mermaidKeywords.some((keyword) =>
    inputLowerCase.includes(keyword)
  );

  return isMermaidRequest;
};

// we extract the code generated, if it starts with backticks or mermaid we gonna only take the code in between
// export const extractMermaidCode = (text) => {
//   const regex = /^```(?:mermaid)?\s*([\s\S]*?)\s*```$/;
//   const match = text.match(regex);
//   if (match) {
//     return match[1];
//   }
//   return text;
// };

export const extractMermaidCode = (text) => {
  const regex = /```(mermaid)?\s*([\s\S]*?)\s*```/g;
  const match = regex.exec(text);
  if (match) {
    return match[2].trim();
  }
  return text;
};