import {useEffect} from 'react'
import packageJson from '../../package.json';

const useCache = () => {
    useEffect(() => {
        const checkAndUpdateCache = () => {
          let version = localStorage.getItem('version-zuro');
          if (version !== packageJson.version) {
            if ('caches' in window) {
              caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach((name) => {
                  caches.delete(name);
                });
              });
              window.location.reload(true);
            }
            localStorage.clear();
            localStorage.setItem('version-zuro', packageJson.version);
          }
        };
    
        checkAndUpdateCache();
      }, []);
  return (
   <></>
  )
}

export default useCache