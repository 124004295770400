import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { useState, useContext } from "react";
import Multiselect from "../layouts/Multiselect";
import {
  categories,
  crossindustries,
  functions,
  industries,
} from "../../config/constants";
import { useTranslation } from "react-i18next";
import { languageContext } from "../../App";
import Switches from "../layouts/Switches";

function Icon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 4.99623C15.0007 5.12789 14.9754 5.25841 14.9254 5.3803C14.8755 5.50219 14.8018 5.61305 14.7087 5.70653L8.68312 11.7091C8.49496 11.8954 8.24043 12 7.97511 12C7.7098 12 7.45527 11.8954 7.2671 11.7091L1.24148 5.70653C1.07696 5.51515 0.990986 5.26897 1.00075 5.01719C1.01051 4.76541 1.11529 4.52656 1.29414 4.34839C1.473 4.17022 1.71276 4.06585 1.96551 4.05612C2.21826 4.0464 2.46539 4.13204 2.6575 4.29593L7.97009 9.58818L13.2827 4.29593C13.4225 4.15545 13.6011 4.05944 13.7957 4.02004C13.9904 3.98064 14.1924 3.99961 14.3762 4.07457C14.56 4.14953 14.7174 4.2771 14.8284 4.44115C14.9394 4.60521 14.9991 4.79837 15 4.99623Z"
        fill="#1E2132"
      />
    </svg>
  );
}

const LeftSection = ({
  selectedOptions,
  setSelectedCategory,
  setSelectedFunctions,
  setSelectedIndustries,
  setSelectedOptions,
  setSelectedCross,
  toggleOne, 
  setToggleOne, 
  setToggleTwo,
  toggleTwo,
  toggleThree,
  setToggleThree 
}) => {
  const { t } = useTranslation();
  const { globalLang } = useContext(languageContext);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <section className="pb-4 lg:flex hidden flex-col transition ease-in-out delay-650 mr-2">
      <div className=" flex justify-between flex-col" style={globalLang ? { direction: "rtl" } : {}}>
        <div>
          <div className="flex items-center 2xl:mb-[20px] mb-3">
            <h3 className="text-gray-900 2xl:text-[20px] text-[16px] font-semibold">
              {t("left.context")}
            </h3>
          </div>
          <Switches
             t={t}
             globalLang={globalLang}
             toggleOne={toggleOne}
             toggleTwo={toggleTwo}
             toggleThree={toggleThree}
             setToggleOne={setToggleOne}
             setToggleTwo={setToggleTwo}
             setToggleThree={setToggleThree}
          />
        </div>
        <div className="leftSection font-poppins">
          <div className="flex items-center 2xl:mb-[20px] mb-3">
            <h3 className="text-gray-900 2xl:text-[20px] text-[16px] font-semibold">
              {t("left.h1")}
            </h3>
          </div>

          <Accordion
            icon={<Icon />}
            open={open === 2}
            className={`mt-2 bg-white border-gray-300 border cursor-pointer px-4 rounded-[6px] mt-2`}
          >
            <AccordionHeader
              onClick={() => handleOpen(2)}
              className="border-0 py-4"
            >
              <p className={`text-[16px] ml-2 text-gray-900 font-bold`}>
                {t("left.h2")}
              </p>
            </AccordionHeader>
            <AccordionBody className="py-0 px-2 h-[200px] overflow-y-scroll">
              <Multiselect
                options={categories}
                selectLabel="technology"
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                setSelectedCategory={setSelectedCategory}
              />
            </AccordionBody>
          </Accordion>
          <Accordion
            icon={<Icon />}
            open={open === 3}
            className={`mt-2 bg-white border-gray-300 border cursor-pointer px-4 rounded-[6px] mt-2`}
          >
            <AccordionHeader
              onClick={() => handleOpen(3)}
              className="border-0 py-4"
            >
              <p className={`text-[16px] ml-2 text-gray-900 font-bold`}>
                {t("left.h3")}
              </p>
            </AccordionHeader>
            <AccordionBody className="py-0 px-2 h-[200px] overflow-y-scroll">
              <Multiselect
                options={industries}
                selectLabel="industry"
                selectedOptions={selectedOptions}
                setSelectedIndustries={setSelectedIndustries}
                setSelectedOptions={setSelectedOptions}
                setSelectedCategory={setSelectedCategory}
              />
            </AccordionBody>
          </Accordion>
          <Accordion
            icon={<Icon />}
            open={open === 4}
            className={`mt-2 bg-white border-gray-300 border cursor-pointer px-4 rounded-[6px] mt-2`}
          >
            <AccordionHeader
              onClick={() => handleOpen(4)}
              className="border-0 py-4"
            >
              <p className={`text-[16px] ml-2 text-gray-900 font-bold`}>
                {t("left.h4")}
              </p>
            </AccordionHeader>
            <AccordionBody className="py-0 px-2 h-[200px] overflow-y-scroll">
              <Multiselect
                options={crossindustries}
                selectLabel="crossindustry"
                selectedOptions={selectedOptions}
                setSelectedCross={setSelectedCross}
                setSelectedOptions={setSelectedOptions}
                setSelectedCategory={setSelectedCategory}
              />
            </AccordionBody>
          </Accordion>
          <Accordion
            icon={<Icon />}
            open={open === 5}
            className={`my-2 bg-white border-gray-300 border cursor-pointer px-4 rounded-[6px] mt-2`}
          >
            <AccordionHeader
              onClick={() => handleOpen(5)}
              className="border-0 py-4"
            >
              <p className={`text-[16px] ml-2 text-gray-900 font-bold`}>
                {t("left.h5")}
              </p>
            </AccordionHeader>
            <AccordionBody className="py-0 px-2">
              <Multiselect
                options={functions}
                selectLabel="functions"
                selectedOptions={selectedOptions}
                setSelectedFunctions={setSelectedFunctions}
                setSelectedOptions={setSelectedOptions}
                setSelectedCategory={setSelectedCategory}
              />
            </AccordionBody>
          </Accordion>

        </div>
      </div>
     
    </section>
  );
};
export default LeftSection;
