import { generateImage } from "../api/dalleService";
import { chatapi } from "../api/openaiService";
import { searchInternet } from "../api/searchInternetService";
import mermaid from "mermaid";
import { checkIfHighchart, extractHighchart } from "./highchart";
import { checkIfMermaid, extractMermaidCode } from "./mermaid";
import axios from "axios";
import { checkIfImage } from "./images";



export const getRequestType = (input) => {
  const inputLowerCase = input.toLowerCase();

  if (checkIfMermaid(inputLowerCase)) return "mermaid";
  else if (checkIfHighchart(inputLowerCase)) return "highcharts";
  else if (checkIfImage(inputLowerCase)) return "image";
  return "";
};

// When the user send a message we display it directly in the screen, and wait for the backend response
export const parseAndSetUserMessage = (
  data,
  setChatLog,
  input,
  setFormData,
  formData
) => {
  let element = input;
  let reqType, userMessage;

  if (data === "submit") {
    reqType = getRequestType(input);
    userMessage = { role: "user", content: `${input}` };
  } else {
    reqType = getRequestType(data);
    userMessage = { role: "user", content: `${data}` };
  }
  setChatLog((chatLog) => [...chatLog, userMessage]);
  setFormData({ ...formData, input: "" });

  return { element, reqType };
};

// This function send the user request to the backend and returns the response and store it the varisable result
export const handleRequest = async (
  data,
  toggleOne,
  toggleThree,
  element,
  reqType,
  payload,
  conversationId,
  userId,
  lang,
  source
) => {
  let result;
  if (reqType === "image") {
    result = await generateImage(element, userId, source);
  } else if (toggleOne) {
     if (data !== "submit") {
      result = await searchInternet(data, conversationId, userId, source);
    } else {
      result = await searchInternet(element, conversationId, userId, source);
    }
    
  } else if(toggleThree) {
    if (data !== "submit") {
      result = await chatapi(data, "", payload, lang, source);
    } else {
      result = await chatapi(element, reqType, payload, lang, source);
    }
  } 
  return result;
};

const fetchdata = async (urlList, cancelToken) => {
  let urls = [];
  try {
  for(let i=0; i<urlList.length; i++){
    const response = await axios.get(`https://jsonlink.io/api/extract?url=${urlList[i]}`,
    { cancelToken: cancelToken.token });
    urls.push({
             title: response.data.title,
             description: response.data.description,
             image: response.data.images[0],
             url: response.data.url
           });
    }
  } catch (error) {
    console.error("Error fetching preview data:", error);
  }
  return urls;
}


export const parseAndSetBotResponse = async (result, element, setChatLog, errorSyntax, setIsLoading, cancelToken) => {
  
  let mermaidCode,
    mermaidId,
    highchartId,
    highchartCode,
    reqType;

  let localMermaidError = false;
  let response = result.response;
  if (response) {
    reqType = getRequestType(element);
    if (reqType === "mermaid") {
      mermaidCode = extractMermaidCode(response);
      const handleMermaidParsing = async () => {
        try {
          await mermaid.parse(mermaidCode);
          localMermaidError = false;
          mermaidId = mermaidCode ? `mermaid-${Date.now()}` : null;
        } catch (error) {
          localMermaidError = true;
          console.error("Error:", error);
        }
      };

      await handleMermaidParsing();
    } else if (reqType === "highcharts") {
      highchartId = `highchartId-${Date.now()}`;
      highchartCode = extractHighchart(response, highchartId);
    }
  }
  const responseId = Date.now();
  setChatLog((chatLog) => [
    ...chatLog,
    {
      role: "assistant",
      content: response,
      id: responseId,
      mermaidCode, imgUrl: result.image_url,
      mermaidError: localMermaidError,
      mermaidId, highchartCode,
      highchartError: errorSyntax[highchartId],
      highchartId, skeletons:result.sources ? result.sources.length:null,
      isSkeleton :result.sources ? true : false
    },
  ]);

 let {preview, pdfUrls} = await checkConnectFetchData(result.sources, cancelToken);

  setChatLog((chatLog) => {
    const updatedChatLog = chatLog.map((chat) => {
      if (chat.id === responseId) {
        return {
          ...chat,
          preview,
          pdfUrls,
          isSkeleton: false,
        };
      } else {
        return chat;
      }
    });

    return updatedChatLog;
  });

  setIsLoading(false);
};



const checkConnectFetchData =async (arr, cancelToken)=>{
  let preview, pdfUrls;

  if (arr) {
    let validSources = arr;

    if (arr.length > 4) {
        validSources = arr.filter((url) => {
            if (url.includes("digitalrosh-connect")) {
                return false;
            } else {
                return true;
            }
        });
    }

    pdfUrls = validSources.filter((url) => url.endsWith(".pdf"));
    const otherUrls = validSources.filter((url) => !url.endsWith(".pdf"));

    if (otherUrls.length > 0) {
      preview = await fetchdata(otherUrls, cancelToken);
    }
}
return {preview, pdfUrls};
}