import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Header from "./components/layouts/Header";
import ChatPage from "./pages/ChatPage";
import useCache from "./hooks/useCache";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InsightsPage from "./pages/InsightsPage";

export const languageContext = createContext();


function App() {

  const { i18n } = useTranslation();
  const [globalLang, setglobalLang] = useState(false);

  useEffect(() => {
    if (i18n.language === 'arabic' || i18n.language === 'hebrew'
    || i18n.language === 'yiddish') {
      setglobalLang(true)
    } else {
      setglobalLang(false)
    }
  }, [i18n.language]);

  useCache();

  return (
    <>
    <languageContext.Provider value={{globalLang, setglobalLang}}>
        <Router>
          <div className="">
          <div className="gtranslate_wrapper"></div>
          <div className={`bg-[#f2f7fa] font-arimo overflow-y-hidden`}>
                <Header />
                <Routes>
                  <Route path="/" element={<ChatPage />} />
                  <Route path="/insights" element={<InsightsPage  />}  />
                  <Route path="/*" element={<Navigate to="/"  replace/>}  />
                </Routes>
            </div>
          </div>
        </Router>
      </languageContext.Provider>
    </>

  );
}

export default App;
