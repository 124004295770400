import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { options4, options5, options6 } from '../config/constants';
import Cards from '../components/sections/Cards';
import DataAccordion from '../components/sections/dataAccordion';
import Insights from '../components/sections/Insights';



const InsightsPage = () => {
   
  return (
    <>
    <section className='xl:mx-[240px] my-[40px] lg:mx-[140px] mx-[40px]'>
        <div className='mb-[30px]'>
            <h1 className="font-bold text-[32px] leading-[30px] text-black">Insights</h1>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[20px] mb-[20px]">
            <Cards title="Number of users" numberEl="195" />
            <Cards title="Number of questions" numberEl="331" />
            <Cards title="Responses rated 4* or more" numberEl="86%"/>
            <Cards title="Avg time saved per user" numberEl="46 min" />
        </div>
        <div className="grid lg:grid-cols-2 gap-[20px] mb-[20px]">
            <div className="bg-white dark:bg-transparent p-[20px] rounded-[6px] border border-gray-500">
                <div className="flex justify-between mb-8">
                    <h3 className='text-[14px] uppercase font-semibold leading-[22px]'>Time saved per user</h3>
                </div>
                <HighchartsReact highcharts={Highcharts} options={options4} />
                <div className="flex mt-4 items-center justify-center">
                    <div className="rounded-full bg-orange-500 w-[7px] h-[7px] mr-2"></div>
                    <p className='text-[14px] text-gray-500 font-avenir-regular leading-[24px]'>Per Day</p>
                </div>
            </div>
            <div className="bg-white dark:bg-transparent p-[20px] rounded-[6px] border border-gray-500">
                <div className="flex justify-between  mb-4">
                    <h3 className='text-[14px] uppercase font-semibold leading-[22px]'>Queries per user</h3>
                </div>
                <HighchartsReact highcharts={Highcharts} options={options5} />
                <div className="flex mt-4 items-center justify-center">
                    <div className="rounded-full bg-[#8C77E9] w-[7px] h-[7px] mr-2"></div>
                    <p className='text-[14px] text-gray-500  leading-[24px]'>Label for the circle</p>
                </div>
            </div>
            <Insights />
            <div className="bg-white p-[20px] rounded-[6px] border border-gray-500">
                <div className="flex justify-between mb-4">
                    <h3 className='text-[14px] text-black uppercase font-avenir-medium leading-[22px]'>Data sources</h3>
                </div>
                <div className="flex justify-center ">
                <HighchartsReact highcharts={Highcharts} options={options6} />
                </div>
                <div className="flex mt-4 items-center justify-center">
                    <div className="rounded-full bg-[#8C77E9] w-[7px] h-[7px] mr-2"></div>
                    <p className='text-[14px] text-gray-500 leading-[24px]'>Label for the circle</p>
                </div>
            </div>
        </div>
        <DataAccordion />
    </section>
</>
  )
}

export default InsightsPage