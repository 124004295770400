import React from 'react'

const TagItem = ({item, remove}) => {
  return (
    <div className="filterText mr-2 my-1 text-[12px] items-center rounded-[4px] cursor-pointer bg-[#E2E2E2] text-black border-0 px-[8px] py-[6px] flex justify-between">
        <p>{item}</p>
        <span className='ml-1' onClick={() => remove(item)}>
            <svg width="8" height="8" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.13754 11.9939C0.843336 12.0112 0.553996 11.9122 0.330533 11.7179C-0.110178 11.2694 -0.110178 10.545 0.330533 10.0966L9.98066 0.334088C10.439 -0.0998239 11.1583 -0.075703 11.5872 0.38801C11.9751 0.807343 11.9977 1.45187 11.6402 1.89794L1.93317 11.7179C1.71259 11.9094 1.42789 12.0082 1.13754 11.9939Z" fill="#0B101C" />
                <path d="M10.7764 11.9938C10.4782 11.9925 10.1924 11.8728 9.98073 11.6603L0.330564 1.89783C-0.0777323 1.41549 -0.0222223 0.689586 0.454572 0.276501C0.880124 -0.092167 1.50773 -0.092167 1.93324 0.276501L11.6402 10.039C12.0985 10.473 12.1222 11.2007 11.6932 11.6643C11.6761 11.6827 11.6585 11.7006 11.6402 11.7178C11.4025 11.9269 11.0897 12.0268 10.7764 11.9938Z" fill="#0B101C" />
            </svg>
        </span>
    </div>
  )
}

export default TagItem