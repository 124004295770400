import React from 'react'

const Switches = ({t, globalLang, toggleOne, toggleTwo, setToggleTwo, setToggleOne, toggleThree, setToggleThree }) => {
  return (
    <div className={`2xl:mb-[20px] flex flex-col  items-end`} style={globalLang ? { direction: "rtl" } : {}}>
            <div className={`flex justify-between w-full mb-2 ${globalLang ? '' : ''}`}>
                <p className={`${globalLang ? 'ml-2' : 'mr-2'} text-black text-md`}>{t("controls.p1")}</p>
                  <label className={`toggle-switch`} onClick={() => { if (!toggleOne) { setToggleOne(true); setToggleThree(false); } }}>
                  <input type="checkbox" checked={toggleOne} readOnly />
                  <div className="toggle-switch-background">
                    <div className="toggle-switch-handle"></div>
                  </div>
                </label>
            </div>
            <div className={`flex justify-between w-full mb-2 ${globalLang ? '' : ''}`} >
            <p className={`${globalLang ? 'ml-2' : 'mr-2'}  text-black text-md`}>{t("controls.p2")}</p>
              <label className={`toggle-switch`} onClick={() => { if (!toggleThree) { setToggleThree(true); setToggleOne(false); } }}>
                <input type="checkbox" checked={toggleThree} readOnly />
                <div className="toggle-switch-background">
                  <div className="toggle-switch-handle"></div>
                </div>
              </label>
            </div>
            <div className={`flex justify-between w-full mb-2 ${globalLang ? '' : ''}`} >
            <p className={`${globalLang ? 'ml-2' : 'mr-2'} ${toggleTwo ? ' text-black' : 'text-gray-500'} text-md`}>{t("controls.p4")}</p>
              <label className={`toggle-switch`}>
                <input type="checkbox" checked={toggleTwo} readOnly />
                <div className="toggle-switch-background">
                  <div className="toggle-switch-handle"></div>
                </div>
              </label>
            </div>
            <div className={`flex justify-between w-full mb-2 ${globalLang ? '' : ''}`} >
            <p className={`${globalLang ? 'ml-2' : 'mr-2'} ${toggleTwo ? ' text-black' : 'text-gray-500'} text-md`}>{t("controls.p5")}</p>
              <label className={`toggle-switch`}>
                <input type="checkbox" checked={toggleTwo} readOnly />
                <div className="toggle-switch-background">
                  <div className="toggle-switch-handle"></div>
                </div>
              </label>
            </div>
      </div>
  )
}

export default Switches