import { useState, useEffect, useContext } from "react";
import { languageContext } from "../../App";

const Multiselect = ({
  selectedOptions,
  setSelectedOptions,
  options,
  selectLabel,
  setSelectedIndustries,
  setSelectedFunctions,
  setSelectedCross,
  setSelectedCategory,
}) => {
  const [isAllChecked, setIsAllChecked] = useState(false);
  const { globalLang} = useContext(languageContext);

  const handleSelectedOptions = () => {
    const selectedLabels = options
      .filter((option) => selectedOptions.includes(option.id))
      .map((option) => option.label);
    if (selectLabel === "technology") {
      setSelectedCategory(selectedLabels);
    } else if (selectLabel === "industry") {
      setSelectedIndustries(selectedLabels);
    } else if (selectLabel === "crossindustry") {
      setSelectedCross(selectedLabels);
    } else if (selectLabel === "functions") {
      setSelectedFunctions(selectedLabels);
    }
  };

  useEffect(() => {
    if (selectedOptions) {
      handleSelectedOptions(
        options
          .filter((option) => selectedOptions.includes(option.id))
          .map((option) => option.label)
      );
    }
  }, [selectedOptions]);

  return (
    <>
      {options.map((option) => (
        <div key={option.id} className="mb-4">
          <label className="">
            <input
              type="checkbox"
              className="w-4 h-4"
              checked={
                selectedOptions.includes(option.id) ||
                (option.id === 1 && isAllChecked)
              }
              onChange={(event) => {
                const optionId = option.id;
                const isChecked = event.target.checked;

                setSelectedOptions((prevSelectedOptions) => {
                  if (isChecked) {
                    return [...prevSelectedOptions, optionId];
                  } else {
                    return prevSelectedOptions.filter((id) => id !== optionId);
                  }
                });

                if (option.id === 1) {
                  // Handle the "All Technologies" checkbox separately
                  setIsAllChecked(isChecked);

                  if (isChecked) {
                    const allOptionIds = options.map((option) => option.id);
                    setSelectedOptions(allOptionIds);
                    handleSelectedOptions(
                      options.map((option) => option.label)
                    );
                  } else {
                    setSelectedOptions([]);
                    handleSelectedOptions([]);
                  }
                } else {
                  if (selectLabel === "technology") {
                    setSelectedCategory((prevSelectedCat) => {
                      if (isChecked) {
                        return [...prevSelectedCat, option.label];
                      } else {
                        return prevSelectedCat.filter(
                          (label) => label !== option.label
                        );
                      }
                    });
                  }
                }
              }}
            />
            <span
              className={` ${
                selectedOptions.includes(option.id) ||
                (option.id === 1 && isAllChecked)
                  ? "text-blue-500"
                  : "text-gray-900"
              } ${globalLang ? 'mr-[10px]' :'ml-[10px]'} text-[16px]`}
              style={{ fontWeight: 400 }}
            >
              {option.label}
            </span>
          </label>
        </div>
      ))}
    </>
  );
};

export default Multiselect;
